import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const Generic = ({
  name,
  label,
  description,
  validator = [],
  error,
  children,
}) => {
  const { t } = useTranslation();
  const isRequired =
    validator &&
    validator.some((v) => {
      return v.required;
    });

  return (
    <div className="GenericInput">
      <label className="GenericInput-Label" htmlFor={name}>
        <div className="GenericInput-label">{label}</div>
        <div>
          {error && <span className="GenericInput-error">{error}</span>}{' '}
          {!error && isRequired && (
            <span className="GenericInput-isRequired">
              {t('global.required')}
            </span>
          )}
        </div>
      </label>
      {description && (
        <span className="GenericInput-description">{description}</span>
      )}
      {children}
    </div>
  );
};

Generic.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validator: PropTypes.arrayOf(
    PropTypes.shape({
      required: PropTypes.bool,
      error: PropTypes.string,
    }),
  ),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Generic.defaultProps = {
  label: '',
  description: undefined,
  error: undefined,
  validator: [],
};

export default Generic;
