import { useState } from 'react';

function loadScript(lang, setReady) {
  window.initMaps = () => {
    console.log('google ready!');
    setReady(true);
  };
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyAxKOW30NdVWQdXtbdV4YEVj8s-OmDfZWs&libraries=places,geometry,marker&callback=initMaps';
  if (lang) {
    script.src += `&language=${lang}`;
  }
  script.id = 'google-maps-script';
  document.head.appendChild(script);
}

function changeGoogleMapsLanguage(lang, setReady) {
  const oldScript = document.getElementById('google-maps-script');
  if (oldScript?.src.includes(`&language=${lang}`)) {
    console.log(`google language already set to ${lang}`);
  } else if (oldScript) {
    oldScript.parentNode.removeChild(oldScript);
    setReady(false);
    delete google.maps;
    loadScript(lang, setReady);
  }
}

function useGoogleMaps() {
  const [ready, setReady] = useState(false);
  const setGoogleLanguage = (lang) => {
    changeGoogleMapsLanguage(lang, setReady);
  };
  return { googleReady: ready, setGoogleLanguage };
}

export default useGoogleMaps;
